import React, { useState } from "react";
import data from "./raw_data.json";
import ObservationCard from "./components/ObservationCard";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>Perceptyx Salesperson Dashboard</h1>
      <h2>{data.name}</h2>
      <div className="observation-list">
        {data.observations.map((observation, index) => (
          <ObservationCard key={index} observation={observation} />
        ))}
      </div>
    </div>
  );
}

export default App;
